import * as React from "react"
import { makeStyles } from "@material-ui/core"

import Layout from "../components/layout"
import SEO from "../components/seo"

const useStyles = makeStyles(theme => ({
  wrapper: {
    lineHeight: 1.5,

    "& *": {
      marginTop: ".5em",
    },

    "& ul": {
      paddingLeft: "1.5em",
    },

    "& h1,h2,h3,h4,h5,h6": {
      marginTop: "1em",
    },
  },
}))

const IndexPage = ({ pageContext: { doc } }) => {
  const classes = useStyles()
  return (
    <Layout>
      <SEO title={doc.data.name} contactPage={true}/>

      <div
        className={classes.wrapper}
        dangerouslySetInnerHTML={{ __html: doc.data.content.text }}
      />
    </Layout>
  )
}

{
  /* <div itemscope itemtype="http://schema.org/Organization">
    <h1 itemprop="name">Контакты</h1>

    <h2 itemprop="department">Колл-центр</h2>
    <div itemprop="description">Если у вас есть вопросы, позвоните в службу поддержки c 10:00 до 22:00</div>
    <a itemprop="telephone" href="tel:+78126288894">+78126288894</a>

    <h2 itemprop="department">Партнерам</h2>
    <div itemprop="description">Узнайте подробные условия для сотрудничества</div>
    <a itemprop="email" href="mailto:seller@krypton.ru">seller@krypton.ru</a>

    <h2 itemprop="department">Маркетинг и реклама</h2>
    <div itemprop="description">Принимаем предложения совместного продвижения ваших товаров и услуг</div>
    <a itemprop="email" href="mailto:marketing@krypton.ru">marketing@krypton.ru</a>

    <h2>Реквизиты</h2>
    <div itemprop="description">
        <b>Полное наименование организации:</b> Общество с ограниченной ответственностью «Маркетплейс»<br>
        <b>ИНН:</b> 7816715969<br>
        <b>КПП:</b> 781601001<br>
        <b>ОГРН:</b> 1217800044219<br>
        <b>Расчетный счет:</b> 40702810110000785868<br>
        <b>Банк:</b> АО "ТИНЬКОФФ БАНК"<br>
        <b>БИК банка:</b> 044525974<br>
        <b>Корреспондентский счет банка:</b> 30101810145250000974
    </div>
</div> */
}

/**
 * Шаблон страницы документа
 * @module src/templates/document
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.data - объект данных полученый из prismic
 * @param {Object} props.pageContext - объект контекста, передаваемый при формировании страницы
 */
export default IndexPage
